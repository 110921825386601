/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        marginBottom: rhythm(2),
      }}
    >
      <p>
        Hi! My name is Doğan, and this is my <i>personal</i> website. Here I
        will share what I learn with me and you. Opinions are my own.
      </p>

      <p>
        I spent most of my time coding in JavaScript. Outside of programming, I
        focus on music and drawing. I would like to write short, often, and not
        just on software.
      </p>
    </div>
  )
}

export default Bio
